import React, { useState, createContext } from "react";

export const StateContext = createContext();

const StateProvider = ({ children }) => {
  const [state, setState] = useState(null);

  return (
    <StateContext.Provider value={{ state, setState }}>
      {children}
    </StateContext.Provider>
  );
};

const useSiteContext = () => {
  const context = React.useContext(StateContext);
  if (context === undefined) {
    throw new Error(`useSiteContext must be used within a SiteProvider`);
  }
  return context;
};

export { StateProvider, useSiteContext };
